<template>
  <!-- <v-sheet max-height="300px" class="d-flex flex-column pa-2 rounded" style="border: 1px solid #A4A4A4"> -->
  <v-sheet class="d-flex flex-column">
    <span class="text-caption" v-if="selectedProducts">{{ label }}</span>

    <!-- <div class="d-flex justify-space-between">
          <v-treeview
            v-model="selectedProduct"
            :items="productTree"
            return-object
            selectable
            selected-color="primary"
            selection-type="leaf"
          >
            <template v-if="showFolderIcon" v-slot:prepend="{ item }">
              <v-icon v-if="item.children && item.children.length > 0">mdi-folder-outline</v-icon>
            </template>
          </v-treeview>
          <v-divider class="mx-2" vertical></v-divider>
      <div class="flex-grow-1 ma-2">
        <template v-if="!selectedProduct.length">
          No product selected.
        </template>
        <template v-else>
          <div
            class="my-2"
            v-for="(product, index) in selectedProduct"
            :key="product.id"
          >
            {{index+1}}. {{ product.name }}
          </div>
        </template>
      </div>
</div> -->
    <treeselect
      :multiple="multiple"
      :options="productTree"
      :disable-branch-nodes="true"
      :show-count="true"
      :placeholder="label"
      v-model="selectedProducts"
      @input="onUpdate"
      :required="required"
      v-bind="$attrs"
      :key="ts"
    />
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    value: {
      type: [String, Number, Object, Array],
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: "Select a product",
    },
    returnObject: {
      type: Boolean,
      required: false,
      default: false,
    },
    manufacturedOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    purchasedOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFolderIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters("navigation", ["isAppLoading"]),
    ...mapGetters("products", ["products"]),
  },
  watch: {
    value(newValue) {
      console.log("product select value changed: ", newValue);
      this.initSelected(newValue);

      console.log("selected products: ", this.selectedProducts);
      this.ts = Date.now();
    },
  },
  components: {
    Treeselect,
  },
  data: () => {
    return {
      productTree: [],
      selectedProducts: null,
      ts: Date.now(),
    };
  },
  mounted() {
    this.initSelected(this.value);

    this.loadProducts().then((resp) => {
      console.log("finished load products");

      this.productTree = resp.filter((product) => product.level == 1);

      if (this.manufacturedOnly) {
        this.productTree = this.productTree.filter(
          (p) => p.name == "Manufactured"
        );
      }
      if (this.purchasedOnly) {
        this.productTree = this.productTree.filter(
          (p) => p.name == "Purchased"
        );
      }

      // Flatten the product tree into a list of { id, name } objects
      const productList = this.flattenProductTree(this.productTree);

      // Emit the onLoad event with the flattened product list
      this.$emit("onLoad", productList);

      this.onUpdate(this.selectedProducts);
    });
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("products", ["loadProducts"]),
    onUpdate(productIds) {
      let selectedProducts = null;
      if (this.multiple) {
        selectedProducts =
          productIds && productIds.length > 0
            ? this.products.filter(
                (p) => productIds && productIds.includes(p.id)
              )
            : [];
      } else {
        console.log("type of: " + typeof productIds);
        const id =
          productIds && typeof productIds == "object"
            ? productIds.id
            : productIds;
        selectedProducts = id ? this.products.find((p) => p.id === id) : null;
      }

      if (this.returnObject) {
        this.$emit("input", selectedProducts);
      } else {
        if (this.multiple) {
          const productIds = selectedProducts
            ? selectedProducts.map((p) => p.id)
            : [];
          this.$emit("input", productIds);
        } else {
          this.$emit("input", selectedProducts ? selectedProducts.id : null);
        }
      }
    },
    initSelected(val) {
      if (val) {
        if (typeof val == "number" || typeof val == "string") {
          if (this.multiple) {
            this.selectedProducts = val ? [val] : null;
          } else {
            this.selectedProducts = val;
          }
        } else if (Array.isArray(val)) {
          if (this.multiple) {
            this.selectedProducts = val.map((p) => p.id);
          } else {
            this.selectedProducts = val && val.length > 0 ? val[0].id : null;
          }
        } else if (typeof val == "object") {
          if (this.multiple) {
            this.selectedProducts = val && val.id ? [val.id] : [];
          } else {
            this.selectedProducts = val && val.id ? val.id : null;
          }
        }
      } else {
        this.selectedProducts = this.multiple ? [] : null;
      }
    },
    flattenProductTree(tree) {
      let flatList = [];

      function traverse(node) {
        if (node.children) {
          for (const child of node.children) {
            traverse(child);
          }
        }

        flatList.push({
          id: node.id,
          name: node.name,
          measureType: node.measureType,
          measureUnit: node.measureUnit,
          measureWeight: node.measureWeight,
        });
      }

      for (const root of tree) {
        traverse(root);
      }

      return flatList;
    },
  },
};
</script>

<style></style>
